import React from "react"
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../assets/css/styles.css'
import { connect } from 'react-redux'
import { toast } from "react-toastify"
import lokkLogo from "./../assets/images/wortenLogo.svg"
import wortenIllustration from "./../assets/images/wortenIllustration.svg"
import axios from 'axios'
import { QRCode } from 'react-qrcode-logo'
import Keyboard from "../components/Keyboard"
import scssVars from "./../assets/sass/vars.scss"
import { changeLanguage } from './../redux/actions/i18n'
import { withTranslation } from 'react-i18next'



class Delivery extends React.Component {
  state = {
    maxPinLength: 6,
    qrCode: process.env.REACT_APP_QRCODE_VALUE,
    pin: '',
    savedPin: '',
    inputRegex: new RegExp('^[0-9]+$'),
    keys: [],
    animation: '',
    showQrcode: false,
    showKeyboard: true,
    isSubmitButtonDisabled: true,
    currentDoor: '',
    lang: this.props.lang,
    showOpenDoorModal: false,
  }

  componentDidMount() {
    this.generateKeys()
    this.props.i18n.changeLanguage(this.props.lang)
  }

  changeLanguage = (e, lang) => {
    e.stopPropagation()
    this.props.dispatch(changeLanguage(lang)).then(() => {
      this.props.i18n.changeLanguage(lang)
      toast.success(this.props.t("toast.change-lang"), { toastId: lang, position: toast.POSITION.TOP_CENTER },)
    })
  }


  resetActivityTimer = () => {
    var self = this
    clearTimeout(this.activityTimer)
    this.setState({ showOpenDoorModal: false })
    this.activityTimer = setTimeout(
      function () {
        self.setState({
          pin: '',
          isSubmitButtonDisabled: true
        })
        //reset language to pt
        if (!self.state.showOpenDoorModal) {
          self.props.dispatch(changeLanguage('pt')).then(() => {
            self.props.i18n.changeLanguage('pt')
          })
        }
      }, 5000)
  }

  cleanPin = () => {
    this.setState({
      pin: '',
      isSubmitButtonDisabled: true,
    })
  }

  generateKeys = () => {
    var keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
    //shuffles keys into a random order
    // for (let i = keys.length - 1; i > 0; i--) {
    //     const j = Math.floor(Math.random() * i)
    //     const temp = keys[i]
    //     keys[i] = keys[j]
    //     keys[j] = temp
    // }

    keys.push('⇦')
    keys.splice(keys.length - 2, 0, "#")
    this.setState({ keys: keys })
  }

  handleClick = (val) => {
    this.resetActivityTimer()
    if (val === '#') {
      this.cleanPin()
    } else if (val === '⇦') {
      const newPin = this.state.pin.slice(0, -1)
      this.setState({ pin: newPin }, () => {
        this.checkPinLength()
      })
    } else {
      if (this.state.pin.length < 6) {
        const newPin = this.state.pin.concat(val)
        this.setState({ pin: newPin }, () => {
          this.checkPinLength()
        })
      }
    }
  }

  checkPinLength = () => {
    if (this.state.pin.length < 6) {
      this.setState({ isSubmitButtonDisabled: true })
    } else {
      this.setState({ isSubmitButtonDisabled: false })
    }
  }

  setAnimation = name => {
    this.setState({ animation: name })
    setTimeout(function () {
      this.setState({ animation: '' })
    }.bind(this), 1000)
  }

  modalCloseTimer = () => {
    setTimeout(function () {
      this.setState({ showOpenDoorModal: false })
    }.bind(this), 10000)
  }

  verifyPin = () => {
    this.resetActivityTimer()
    if (!this.state.isSubmitButtonDisabled) {
      this.setState({ isSubmitButtonDisabled: true })
      var self = this
      let pin = this.state.pin
      this.setState({ pin: '', savedPin: pin })
      axios.get(`${process.env.REACT_APP_API_URL}api/delivery/verifyPickUpPackageWithPin?pin=${pin}`, {
        headers: {
          Authorization: 'Bearer ' + process.env.REACT_APP_API_TOKEN
        }
      })
        .then(res => {
          self.setState({ isSubmitButtonDisabled: false })
          if (res.data.ResultCode === 1) {
            this.setState({ currentDoor: res.data.Data.assignedLocker.lockerMetraId })
            self.toggleOpenDoorModal(true)
            self.modalCloseTimer()
            self.setAnimation('success')
          } else {
            toast.error(this.props.t("toast.error-pin-sub"), { toastId: 'error-pin-sub', position: toast.POSITION.TOP_CENTER },)
            self.setAnimation('error')
          }
        })
        .catch((err) => {
          self.setState({ isSubmitButtonDisabled: false })
          self.setAnimation('error')
          toast.error(this.props.t("toast.error-pin-sub"), { toastId: 'error-pin-sub', position: toast.POSITION.TOP_CENTER },)
        })
    }
  }

  onSubmit = () => {
    this.resetActivityTimer()
    if (!this.state.isSubmitButtonDisabled) {
      this.setState({ isSubmitButtonDisabled: true })
      var self = this
      let pin = this.state.savedPin
      this.setState({ savedPin: '' })
      axios.get(`${process.env.REACT_APP_API_URL}api/delivery/pickUpPackageWithPin?pin=${pin}`, {
        headers: {
          Authorization: 'Bearer ' + process.env.REACT_APP_API_TOKEN
        }
      })
        .then(res => {
          self.setState({ isSubmitButtonDisabled: false })
          if (res.data.ResultCode === 1) {
            this.setState({ currentDoor: res.data.Data.assignedLocker.lockerMetraId })
            self.setAnimation('success')
            //reset language to pt
            self.props.dispatch(changeLanguage('pt')).then(() => {
              self.props.i18n.changeLanguage('pt')
            })
          } else {
            toast.error(this.props.t("toast.error-pin-sub"), { toastId: 'error-pin-sub', position: toast.POSITION.TOP_CENTER },)
            self.setAnimation('error')
            //reset language to pt
            self.props.dispatch(changeLanguage('pt')).then(() => {
              self.props.i18n.changeLanguage('pt')
            })
          }
        })
        .catch((err) => {
          self.setState({ isSubmitButtonDisabled: false })
          self.setAnimation('error')
          toast.error(this.props.t("toast.error-pin-sub"), { toastId: 'error-pin-sub', position: toast.POSITION.TOP_CENTER },)
          //reset language to pt
          self.props.dispatch(changeLanguage('pt')).then(() => {
            self.props.i18n.changeLanguage('pt')
          })
        })
    }
  }

  toggleOpenDoorModal = (val) => {
    this.resetActivityTimer()
    this.setState({ showOpenDoorModal: val })
  }

  cancel = () => {
    this.setState({ showOpenDoorModal: false })
  }

  render() {
    const { t } = this.props

    const { showQrcode, showKeyboard, logo, logoFull, qrCode, showOpenDoorModal, isSubmitButtonDisabled } = this.state

    const pinInputs = []

    for (var i = 0; i < this.state.maxPinLength; i++) {
      pinInputs.push(<Col className="col-2 pin-key" key={i}>
        {this.state.pin[i] && <div><div className="pin-div">{this.state.pin[i]}</div><div className="float"></div></div>}
      </Col>)
    }

    const openDoorModal = (
      <Modal isOpen={showOpenDoorModal} toggle={() => this.toggleOpenDoorModal(!this.state.showOpenDoorModal)} centered size="xl">
        <ModalBody className="modal-body modal-size">
          <p className="modal-text"><strong>{t("delivery.codeValidated")}</strong></p>
          <p className="modal-text">{t("delivery.doorHasBeenOpen")}.</p>
          <p className="modal-text">{t("delivery.pleaseRetrieve")}.</p>
          <div className="modal-door-wrapper">
            <span className="modal-door">{this.state.currentDoor}</span>
          </div>
          <Row className="justify-content-center">
            <Button
              className="modal-btn m-3"
              onClick={this.onSubmit}>
              {t("button.openDoor")}
            </Button>
            {/*<Button
              color="danger"
              className="modal-btn m-3"
              onClick={() => this.cancel()}>
              {t("button.cancel")}
            </Button>*/}
          </Row>
        </ModalBody>
      </Modal>
    )

    return (
      <>
        {openDoorModal}
        <div className="office-container container-fluid">

          <Row className="lang-row pt-2">
            <button className={"pt " + (this.props.lang === 'pt' ? "active" : "")} onClick={(e) => this.changeLanguage(e, 'pt')}>PT</button>
            <button className={"en " + (this.props.lang === 'en' ? "active" : "")} onClick={(e) => this.changeLanguage(e, 'en')}>EN</button>
          </Row>
          <Row className="pin-submit-office">
            <Col className="col-5 keyboard-col">
              <Row className="logo-row">
                <a href={process.env.REACT_APP_URL_HOME}>
                  <img src={lokkLogo} className="logo-img" widht="732" height="150" />
                </a>
              </Row>
              <div class="keyboard-wrapper">
                <Row className="justify-content-start align-items-center">
                  <Row
                    className={`input-wrapper ${this.state.animation}`}
                  >
                    {pinInputs}
                  </Row>
                </Row>
                <Row className="justify-content-start keys-row">
                  <Col>
                    <Keyboard
                      handleClick={this.handleClick}
                      keys={this.state.keys}
                      isSubmitting={this.state.isSubmitting}
                      animation={this.state.animation} />
                  </Col>
                </Row>
                <Row className="justify-content-center confirm-button-row">
                  <Button
                    disabled={isSubmitButtonDisabled}
                    className="confirm-btn"
                    onClick={() => this.verifyPin()}>
                    {t("button.btn-confirm")}
                  </Button>
                </Row>
              </div>
            </Col>
            <Col className="col-7 help-section">
              <div className="container-fluid help-container">
                <Row className="section-title">
                  <h2>{t("delivery.title")}</h2>
                </Row>
                {/* <Row className="qr-div justify-content-center">
                  <QRCode
                    value={process.env.REACT_APP_QRCODE_VALUE}
                    fgColor={scssVars.qrcolor}
                    size="200" />
                </Row> */}
                <Row className="subtitle-row">
                  <p className="w-100">
                    {t("delivery.sub-title.text-1")}.
                    {/* <br /><span>{t("delivery.sub-title.or")}</span><br />
                    {t("delivery.sub-title.text-2")} */}
                  </p>
                </Row>
                <Row className="pt-3">
                  <img src={wortenIllustration} height="550" />
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </>
    )
  }
}
const mapStateToProps = state => {
  return {
    lang: state.i18n.language
  }
}

export default connect(mapStateToProps)(withTranslation('common')(Delivery))
